import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Routes,
  Outlet,
} from "react-router-dom";
import Members from "./Members";
import Plans from "./Plans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons/faCalendarWeek";
import { faChartPie } from "@fortawesome/free-solid-svg-icons/faChartPie";

function Dashboard() {
  return (
    <div className="dashboard">
      <nav className="navbar">
        <p className="name">Nassim</p>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {({ isActive }) => (
                <>
                  <FontAwesomeIcon
                    icon={faChartPie}
                    style={{ color: isActive ? "#1e274e" : "gray" }}
                  />
                  <span>Summary</span>
                </>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="members"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {({ isActive }) => (
                <>
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ color: isActive ? "#1e274e" : "gray" }}
                  />
                  <span>Members</span>
                </>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="plans"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {({ isActive }) => (
                <>
                  <FontAwesomeIcon
                    icon={faCalendarWeek}
                    style={{ color: isActive ? "#1e274e" : "gray" }}
                  />
                  <span>Plans</span>
                </>
              )}
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className="right-side" style={{ width: "80%", padding: "20px" }}>
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
